import { h } from '@bolt/core/renderers';

export const Launchpad = ({ bgColor, fgColor, size, ...otherProps }) => {
  return (
    <svg data-name="Layer 1" {...otherProps} viewBox="0 0 32.01 31.98">
      <path
        fill={bgColor}
        fill-rule="evenodd"
        d="M16.03 23.38c-.1 0-.1 0-.2.1a9.29 9.29 0 00-1 .5l-6.7-6.7a5.9 5.9 0 01.4-.8.52.52 0 00.2-.4 39.83 39.83 0 017.4-10.2c4.8-4.9 11.5-3.8 13.4-3.3.4 1.9 1.5 8.7-3.2 13.4a38.3 38.3 0 01-10.3 7.4m3.1 4a8.28 8.28 0 01-2.7 1.7 13.21 13.21 0 001-4.2c.8-.4 1.9-1 3-1.7a6 6 0 01-1.3 4.2m-16.1-11.7a11.1 11.1 0 011.7-2.7 5.85 5.85 0 014.1-1.3c-.7 1.1-1.3 2.2-1.7 3a12.15 12.15 0 00-4.1 1m28.3-14.3c.1.4 2.9 9.5-3.6 16a41.85 41.85 0 01-5.5 4.6c0 .1 0 .1.1.1 0 .2.7 4.2-1.8 6.7a14.1 14.1 0 01-6.4 3.2h-.2a1.05 1.05 0 01-.9-.5 1.06 1.06 0 01.2-1.2 7.75 7.75 0 002.1-4.4 1.24 1.24 0 00-.3.2c-.1 0-.2.1-.3.1a.91.91 0 01-.7-.3l-7.7-7.7a1.13 1.13 0 01-.3-1.1.77.77 0 01.1-.3 7.48 7.48 0 00-4.4 2.1.91.91 0 01-.7.3.9.9 0 01-.5-.1 1 1 0 01-.5-1.1 15.33 15.33 0 013.2-6.4c2.4-2.4 6.5-1.8 6.7-1.8h.1a41.85 41.85 0 014.6-5.5c6.5-6.5 15.6-3.7 16-3.6a1.2 1.2 0 01.7.7zm-8.5 4a4.3 4.3 0 00-2.8 1.1 3.55 3.55 0 00-1.1 2.8 4.3 4.3 0 001.1 2.8 3.84 3.84 0 002.8 1.1 4.3 4.3 0 002.8-1.1 3.92 3.92 0 000-5.5 3.78 3.78 0 00-2.8-1.2zm1.4 5.2a2 2 0 01-2.7 0 1.82 1.82 0 01-.6-1.4 2 2 0 01.6-1.4 1.82 1.82 0 011.4-.6 2 2 0 011.4.6 2.25 2.25 0 01-.1 2.8zm-19.5 12a3.394 3.394 0 114.8 4.8c-1.1 1.1-4.4 1.6-5.4 1.7h-.1a.91.91 0 01-.7-.3 1.14 1.14 0 01-.3-.8c.1-1 .6-4.3 1.7-5.4zm3.4 3.3a1.39 1.39 0 000-1.9 1.28 1.28 0 00-1-.4 1.28 1.28 0 00-1 .4 7.51 7.51 0 00-.9 2.9 9.18 9.18 0 002.9-1z"
        data-name="path-1"
      />
    </svg>
  );
};
