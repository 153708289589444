import { h } from '@bolt/core/renderers';

export const CustomerDecisionHub = ({
  bgColor,
  fgColor,
  size,
  ...otherProps
}) => {
  return (
    <svg data-name="Layer 1" {...otherProps} viewBox="0 0 32.1 32.1">
      <path
        fill={bgColor}
        fill-rule="evenodd"
        d="M19.9 19.4a14 14 0 00-4 6.5 15.8 15.8 0 00-4.1-6.6A17.29 17.29 0 006.2 16a16.13 16.13 0 006-3.5 15.33 15.33 0 003.7-6.2 15.15 15.15 0 004.6 7.1 15.14 15.14 0 005.2 2.7 13.8 13.8 0 00-5.8 3.3M31.1 15c-3.8-.3-7-1.3-9.3-3.2-2.9-2.3-4.5-6-4.8-10.9a1 1 0 00-1-.9 1.06 1.06 0 00-1 .9c-.4 4.4-1.8 7.9-4.2 10.2S5.1 14.7.9 15a1 1 0 000 2 15 15 0 019.5 3.8c2.5 2.3 4 5.8 4.6 10.4a1.06 1.06 0 001 .9 1.06 1.06 0 001-.9c.3-4.5 1.7-7.9 4.3-10.2 2.2-2.1 5.6-3.4 9.9-3.8a1.06 1.06 0 00.9-1c-.1-.7-.5-1.2-1-1.2"
        data-name="Fill-1"
      />
      <path
        fill={bgColor}
        fill-rule="evenodd"
        d="M10.4 24.9a3.25 3.25 0 01-2.4-1 3.41 3.41 0 01-.8-2.3.5.5 0 00-1 0 3.88 3.88 0 01-1.1 2.5 3.68 3.68 0 01-2.1.8.5.5 0 000 1 5.28 5.28 0 012.2.8 3.36 3.36 0 011.1 2.4.47.47 0 00.5.5.47.47 0 00.5-.5 3.34 3.34 0 01.9-2.3 4 4 0 012.4-1 .47.47 0 00.5-.5 1.27 1.27 0 00-.7-.4"
        data-name="Fill-4"
      />
      <path
        fill={bgColor}
        fill-rule="evenodd"
        d="M21.6 7.1a3.73 3.73 0 012.3 1 3.52 3.52 0 01.9 2.3.47.47 0 00.5.5.47.47 0 00.5-.5 3.34 3.34 0 01.9-2.3 3.69 3.69 0 012.4-1 .5.5 0 000-1 2.94 2.94 0 01-2.2-.9 3 3 0 01-1-2.4.5.5 0 00-1 0 3.77 3.77 0 01-1 2.4 3.28 3.28 0 01-2.2.9.47.47 0 00-.5.5c-.1.3.1.5.4.5"
        data-name="Fill-6"
      />
    </svg>
  );
};
