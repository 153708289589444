import { h } from '@bolt/core/renderers';

export const Vision = ({ bgColor, fgColor, size, ...otherProps }) => {
  return (
    <svg data-name="Layer 1" {...otherProps} viewBox="0 0 31.9 23.9">
      <path
        fill={bgColor}
        fill-rule="evenodd"
        d="M22 12a5.45 5.45 0 00-1.8-4.2A5.45 5.45 0 0016 6a5.45 5.45 0 00-4.2 1.8A5.45 5.45 0 0010 12a5.45 5.45 0 001.8 4.2A5.76 5.76 0 0016 18a5.6 5.6 0 004.2-1.8A5.45 5.45 0 0022 12m2 0a8.06 8.06 0 01-13.7 5.7A8.061 8.061 0 0121.7 6.3 8.1 8.1 0 0124 12m0 6.5c1-.9 2.1-1.9 3.1-3s1.7-1.9 2.1-2.4A5.94 5.94 0 0030 12l-.8-1a19.86 19.86 0 00-2.1-2.5A29.3 29.3 0 0024 5.6a15.35 15.35 0 00-3.9-2.5 9.87 9.87 0 00-4.2-1 10.11 10.11 0 00-4.2 1.1A13.65 13.65 0 008 5.5c-1 .9-2.1 1.9-3.1 3s-1.7 1.9-2.1 2.4A5.94 5.94 0 002 12l.8 1a19.86 19.86 0 002.1 2.5A21.65 21.65 0 008 18.4a21.71 21.71 0 003.9 2.5 9.87 9.87 0 004.2 1 10.11 10.11 0 004.2-1.1 13.65 13.65 0 003.7-2.3m1.4-14.3a24 24 0 013.5 3.6 27.21 27.21 0 012.2 2.9 11.65 11.65 0 01.8 1.3l-.8 1.2a30.6 30.6 0 01-2.3 3c-1 1.2-2.2 2.3-3.4 3.5a17.68 17.68 0 01-4.5 3 11.8 11.8 0 01-5 1.2 10.65 10.65 0 01-5-1.3 21 21 0 01-4.4-2.9A24 24 0 013 16.1a27.21 27.21 0 01-2.2-2.9 11.65 11.65 0 01-.8-1.3l.8-1.2a30.6 30.6 0 012.3-3c1-1.2 2.2-2.3 3.4-3.5a17.68 17.68 0 014.5-3A11.8 11.8 0 0116 0a10.65 10.65 0 015 1.3 18.65 18.65 0 014.4 2.9"
        data-name="Page-1"
      />
    </svg>
  );
};
