// const imageSizes = require('@bolt/core-data/image-sizes/image-sizes.data.json').boltImageSizes;

// @todo Grab the actual data from `./build/data/` - also duplicated in `image-tasks.js`
const imageSizes = [
  50,
  100,
  200,
  320,
  480,
  640,
  800,
  1024,
  1366,
  1536,
  1920,
  2560,
  2880,
];

module.exports.imageSizes = imageSizes;
