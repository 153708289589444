import { h } from '@bolt/core/renderers';

export const EyeOff = ({ bgColor, fgColor, size, ...otherProps }) => {
  return (
    <svg {...otherProps} viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
        <path />
        <g fill={bgColor}>
          <path d="M10.139 4.708a7.894 7.894 0 011.844-.213h.024c2.6 0 5.074 1.225 7.355 3.643a18.854 18.854 0 012.493 3.35 17.653 17.653 0 01-1.8 2.574.993.993 0 00.119 1.405c.189.156.419.237.645.237.28 0 .568-.119.76-.356a19.908 19.908 0 002.3-3.386.992.992 0 00.013-.925c-.044-.087-1.087-2.155-3.037-4.237-2.65-2.812-5.711-4.304-8.855-4.304h-.025c-.793 0-1.594.093-2.318.268a.996.996 0 00-.731 1.206c.138.537.675.87 1.212.738zM12.001 18.492c-2.6 0-5.074-1.231-7.36-3.643a18.654 18.654 0 01-2.494-3.35A17.567 17.567 0 015.99 6.895l2.55 2.549a4.022 4.022 0 00-.612 2.125 4.004 4.004 0 004 3.999 4.04 4.04 0 002.124-.612l2.324 2.324a8.918 8.918 0 01-4.374 1.212zm-1.975-7.554l2.531 2.53a2.005 2.005 0 01-2.531-2.53zm13.178 10.347l-4.549-4.55-.024-.024L2.21.29a1.004 1.004 0 00-1.412 0 1.002 1.002 0 000 1.413L4.57 5.477a19.7 19.7 0 00-4.448 5.536 1.01 1.01 0 00-.019.925c.044.087 1.087 2.156 3.043 4.236 2.65 2.825 5.71 4.318 8.86 4.318h.02a10.913 10.913 0 005.797-1.755l3.962 3.962a.995.995 0 001.411 0 .985.985 0 00.006-1.413l.002-.001z" />
        </g>
      </g>
    </svg>
  );
};
