import { h } from '@bolt/core/renderers';

export const BoltLogoColored = ({ bgColor, fgColor, size, ...otherProps }) => {
  return (
    <svg {...otherProps} viewBox="0 0 198 182">
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#FFCC4C"
          d="M72.75 49h52.5c.966 0 1.75.784 1.75 1.75v31.5a1.75 1.75 0 01-1.75 1.75h-52.5A1.75 1.75 0 0171 82.25v-31.5c0-.966.784-1.75 1.75-1.75z"
        />
        <path
          fill="#F63"
          d="M1.067 78.751l16.659-28.876A1.75 1.75 0 0119.242 49H55.25c.966 0 1.75.784 1.75 1.75v31.5A1.75 1.75 0 0155.25 84H4.098a3.5 3.5 0 01-3.031-5.249z"
        />
        <path
          fill="#E1E2EB"
          d="M141 82.25v-31.5c0-.966.784-1.75 1.75-1.75h36.008a1.75 1.75 0 011.516.875l16.66 28.876A3.5 3.5 0 01193.901 84H142.75a1.75 1.75 0 01-1.75-1.75z"
        />
        <path
          fill="#F63"
          d="M85 1.75v31.5A1.75 1.75 0 0183.25 35H29.338a1.75 1.75 0 01-1.516-2.625L42.459 7.004A14 14 0 0154.586 0H83.25C84.216 0 85 .784 85 1.75z"
        />
        <path
          fill="#FFCC4C"
          d="M100.75 0h42.664a14 14 0 0112.127 7.004l14.637 25.371A1.75 1.75 0 01168.662 35H100.75A1.75 1.75 0 0199 33.25V1.75c0-.966.784-1.75 1.75-1.75z"
        />
        <path
          fill="#6C79D9"
          d="M42.46 174.996l-14.638-25.371A1.75 1.75 0 0129.338 147H97.25c.966 0 1.75.784 1.75 1.75v31.5a1.75 1.75 0 01-1.75 1.75H54.586a14 14 0 01-12.127-7.004z"
        />
        <path
          fill="#12B3B3"
          d="M114.75 147h53.912a1.75 1.75 0 011.516 2.625l-14.637 25.371A14 14 0 01143.414 182H114.75a1.75 1.75 0 01-1.75-1.75v-31.5c0-.966.784-1.75 1.75-1.75z"
        />
        <path
          fill="#6C79D9"
          d="M71 99.75v31.5a1.75 1.75 0 01-1.75 1.75H19.242a1.75 1.75 0 01-1.516-.875l-16.66-28.876A3.5 3.5 0 014.099 98H69.25c.966 0 1.75.784 1.75 1.75z"
        />
        <path
          fill="#12B3B3"
          d="M86.75 98h107.152a3.5 3.5 0 013.031 5.249l-16.659 28.876a1.75 1.75 0 01-1.516.875H86.75a1.75 1.75 0 01-1.75-1.75v-31.5c0-.966.784-1.75 1.75-1.75z"
        />
      </g>
    </svg>
  );
};
