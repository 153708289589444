import { h } from '@bolt/core/renderers';

export const Partners = ({ bgColor, fgColor, size, ...otherProps }) => {
  return (
    <svg data-name="Layer 1" {...otherProps} viewBox="0 0 32 32">
      <path
        fill={bgColor}
        fill-rule="evenodd"
        d="M25.4 16.9a9.75 9.75 0 003.4-3.3A8.14 8.14 0 0030 9a8.32 8.32 0 00-2.7-6.3A8.32 8.32 0 0021 0a8.31 8.31 0 00-4.4 1.2 8.66 8.66 0 00-3.2 3.1 1.69 1.69 0 00.2.5c.2.5.3.8.4 1a2.18 2.18 0 00.4.5c.2.1.3.1.5-.1a5 5 0 00.4-1.3A6.88 6.88 0 0121 2a6.51 6.51 0 014.9 2.1A6.51 6.51 0 0128 9a6.88 6.88 0 01-1.7 4.6 6.56 6.56 0 01-4.3 2.3 6.16 6.16 0 01-.6 2.6c5.8 1 8.6 3.5 8.6 7.4h2q0-6.6-6.6-9zm-9.5 3A6.66 6.66 0 0018 15a6.51 6.51 0 00-2.1-4.9A6.51 6.51 0 0011 8a6.51 6.51 0 00-4.9 2.1A6.51 6.51 0 004 15a6.51 6.51 0 002.1 4.9A6.66 6.66 0 0011 22a6.51 6.51 0 004.9-2.1zM20 32a7 7 0 00-2.3-5.3 9.81 9.81 0 00-6.7-2.2c-3.1 0-5.4.7-6.8 2.1A7 7 0 002 32H0a9.06 9.06 0 011.8-5.5 10.68 10.68 0 014.9-3.6 9.75 9.75 0 01-3.4-3.3A8.21 8.21 0 012 15a8.32 8.32 0 012.7-6.3A8.32 8.32 0 0111 6a8.32 8.32 0 016.3 2.7A8.63 8.63 0 0120 15a8.81 8.81 0 01-4.6 7.9c4.4 1.6 6.6 4.7 6.6 9.1z"
        data-name="Page-1"
      />
    </svg>
  );
};
